import React, { useRef } from "react";
import Layout from "../components/Layout";
import Modal from "../components/Modal";
// import Gift from "../components/Gift";
// import { Link } from "gatsby";
// import Repubblica from "../components/Repubblica";
// import Frutta from "../components/Frutta";
import ThankYou from "../components/ThankYou";
// import Mix from "../components/Mix";
import Form from "../components/Form";
// import Rating from "../components/Rating";
// import Infographic from "../components/Infographic";
// import Bio from "../components/Bio";
// import Prodotto1 from "../components/Prodotto1";
// import Prodotto2 from "../components/Prodotto2";
// import Prodotto3 from "../components/Prodotto3";
// import Accordion from "../components/Accordion";
import "../css/scroller.css";
const { SocialIcon } = require("react-social-icons");
// import RecensioneSara from "../components/RecensioneSara";
// import RecensioneSimona from "../components/RecensioneSimona";
// import ReactPlayer from "react-player/file";

export default () => {
    const modalRef1 = useRef();

    return (
        <Layout>
            <main className="flex flex-grow flex-col justify-center py-2 px-4 md:px-32 mt-8 md:mt-0">
                <section className="flex flex-wrap flex-col-reverse md:flex-row items-center mt-6">
                    <div className="flex flex-col justify-between mx-auto md:ml-0 md:mr-auto max-w-lg text-justify">
                        <h1 className="px-4 pt-5 text-4xl md:text-5xl md:text-justify text-center text-black font-serif tracking-wide">
                            Thank you!
                        </h1>
                        <p className="px-4 mt-2 text-xl text-gray-800 font-sans">
                            You will receive an update when we launch in your city.
                        </p>
                        <p className="px-4 mt-8 text-xl text-gray-800 font-sans">
                            In the meantime, you can follow us on:
                        </p>
                        <div className="inline-block px-4 mt-3 text-xl text-gray-800 font-sans md:text-justify text-center">
                            <span className="w-2 h-2">
                                <SocialIcon
                                    bgColor="#000"
                                    url="https://www.instagram.com/passoladro/"
                                />
                                <SocialIcon
                                    className="ml-1"
                                    bgColor="#000"
                                    url="https://www.facebook.com/passoladro/"
                                />
                                <SocialIcon
                                    className="ml-1"
                                    bgColor="#000"
                                    url="https://it.linkedin.com/company/passoladro/"
                                />
                            </span>
                        </div>

                        <Modal ref={modalRef1}>
                            <Form />
                        </Modal>
                    </div>

                    <div
                        className="mx-auto md:mr-0 md:ml-auto w-full max-w-2xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <ThankYou />
                    </div>
                </section>
            </main>
        </Layout>
    );
};
